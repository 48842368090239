<template>
  <QDialog
    v-model="authStore.showLoginModal"
    persistent
  >
    <div class="!max-w-screen w-full md:(w-3xl px-35) pt-10 px-6 !shadow-none">
      <div class="relative rounded dark:bg-dark-2 light:bg-light-2">
        <QBtn
          v-close-popup
          to="/"
          icon="close"
          flat
          round
          dense
          size="18px"
          class="absolute -top-6 -right-6 bg-secondary"
        />
        <div class="flex flex-nowrap items-center relative">
          <div class="w-full p-5 md:p-10">
            <QForm
              class="flex flex-col gap-5"
              @submit.prevent="authStore.login(form)"
            >
              <div class="flex flex-nowrap justify-center items-center gap-2 w-full border-b border-secondary text-2xl font-bold py-5">
                <Logo
                  class="w-35"
                  :dark="$q.dark.isActive"
                />
              </div>
              <div class="flex flex-col gap-3">
                <div class="text-base font-bold">
                  <QIcon
                    name="person"
                    size="xs"
                    color="secondary"
                  />
                  아이디
                </div>
                <QInput
                  v-model="form.username"
                  placeholder="아이디를 입력하세요"
                  outlined
                  dense
                  :bg-color="$q.dark.isActive ? 'dark-3':'light-3'"
                />
              </div>
              <div class="flex flex-col gap-3">
                <div class="text-base font-bold">
                  <QIcon
                    name="lock"
                    size="xs"
                    color="secondary"
                  />
                  비밀번호
                </div>
                <QInput
                  v-model="form.password"
                  placeholder="비밀번호를 입력하세요"
                  outlined
                  dense
                  :bg-color="$q.dark.isActive ? 'dark-3':'light-3'"
                  type="password"
                />
              </div>
              <div class="md:mt-8 grid grid-cols-1">
                <QBtn
                  color="secondary"
                  unelevated
                  rounded
                  class="h-15"
                  type="submit"
                  :loading="authStore.logging"
                >
                  <div class="text-base font-bold">
                    로그인
                  </div>
                </QBtn>
              </div>
            </QForm>
            <div class="pt-5 flex items-center justify-center">
              <div>아직 회원이 아니신가요?</div>
              <RouterLink
                :to="{name:'registration'}"
                class="text-primary"
              >
                회원가입 바로가기
              </RouterLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  </QDialog>
</template>

<script setup>
import Logo from '@/components/Logo.vue'
import { useAuthStore } from '@/stores/auth.js'
import { reactive } from 'vue'

const authStore = useAuthStore()

const form = reactive({
  username: '',
  password: ''
})
</script>
